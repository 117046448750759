// ///////////////////////// Dynamic Bootstrap /////////////////////////////
import('./routes')

// import('./bootstrap')
// export {}

// /////////////////////// Synchronous Bootstrap ///////////////////////////

// @ts-ignore
import bootstrap from './bootstrap'
bootstrap(() => {})
